.NavigationItems {
   margin: 0;
   padding: 0;
   list-style: none;
   display: flex;
   flex-flow: column;
   height: 100%;
}

.NavigationItemsExtended {
   display: flex;
}
@media (max-width: 720px) {
   .NavigationItemsExtended {
      display: none;
   }
}

@media (min-width: 500px) {
   .NavigationItems {
      flex-flow: row;
   }
}
