.Socialmedia {
   width: 64px;
   display: flex;
   align-content: flex-start;
   box-sizing: border-box;

   margin: 0;
   padding: 0;
}

.Socialmedia a {
   margin: 0px;
   padding: 0px 10px 0px 0px;
}

.Socialmedia img {
   color: violet;
}
