.AppUpdateInfo {
   background-color: #F0F3F7;
   padding: 30px 40px;
   border-radius: 20px;
   display: flex;
}

.Space {
   height: 60px;
}

.Content {
   flex: 2;
   padding-right: 30px;
}

.Image {
   flex: 1;
}

@media (max-width: 600px) {
   .AppUpdateInfo {
      flex-flow: column;
   }
}