.NavigationItem {
   margin: 10px 0px;
   box-sizing: border-box;
   display: block;
   justify-content: center;
   width: 100%;
}

.NavigationItem a {
   color: #575757;
   text-decoration: none;
   width: 100%;
   height: 100%;
   padding-right: 20px;
   font-size: 1.1em;
   box-sizing: border-box;
   display: block;
   text-align: right;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
   color: #cf065b;
}

@media (min-width: 500px) {
   .NavigationItem {
      margin: 0;
      display: flex;
      height: 100%;
      width: auto;
      align-items: center;
   }

   .NavigationItem a {
      height: 100%;
      padding: 20px 10px;
      border-bottom: 4px solid transparent;
   }

   .NavigationItem a:hover,
   .NavigationItem a:active,
   .NavigationItem a.active {
      color: #cf065b;
   }
}

.NavigationItemNoFormat {
   text-decoration: none;
   display: block;
   height: 100%;
}
