.Footer {
   background-color: #4e4e4e;
   box-sizing: border-box;
   margin: 0;
   padding: 0;
}

.Footer .Content {
   margin: auto;
   width: 1080px;
   display: flex;
   justify-content: flex-end;
   align-items: right;
   box-sizing: border-box;
   flex-direction: row;
   padding: 30px 30px 60px 30px;
}

.Footer .MenuGroup {
   padding-left: 45px;
   padding-top: 15px;
}

.Logo {
   width: 100%;
}

.Footer ul {
   margin: 0;
   padding: 0;
}

.Footer h4 {
   color: white;
   margin: 0;
   padding: 0 0 10px 0;
   white-space: nowrap;
}

.NavigationItem {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   display: block;
   justify-content: center;
   width: 100%;
}

.NavigationItem a {
   margin: 0;
   padding: 0;
   color: #adadad;
   text-decoration: none;
   width: 100%;
   height: 100%;
   font-size: 1.1em;
   box-sizing: border-box;
   display: block;
   text-align: left;
   white-space: nowrap;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
   color: #cf065b;
}

@media (max-width: 1200px) {
   .Footer .Content {
      width: 90%;
   }
}

@media (max-width: 899px) {
   .Logo {
      width: auto;
   }

   .Footer .Content {
      flex-wrap: wrap;
   }
}

@media (max-width: 709px) {
   .Footer .Content {
      flex-direction: column;
   }

   .Footer h4 {
      padding: 15px 0 10px 0;
   }

   .Footer .MenuGroup {
      padding-left: 60px;
   }
}
