.Card {
   flex: 0 0 31%; 
   background-color: #f0f3f7;
   box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
   border-radius: 20px;
   display: flex;
   flex-flow: column;
   align-content: stretch;
   height: 280px;
   width: 100%;
   margin-bottom: 30px;
}

.Card .Content {
   flex: 0.75;
   color: #5f5f5f;
   font-size: 1.1em;
   font-style: italic;
   font-weight: 400;
   text-align: center;

   display: flex;
   padding: 10px;
}

.Content p {
   margin: auto;
}

.Card .Image {
   flex: 1;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center center;
   border-radius: 0 0 20px 20px;
}




