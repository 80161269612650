/* * {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
} */
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2em;

  background-color: #f0f3f7;
}

h1 {
  color: #3c81be;
  font-size: 3.2em;
  font-weight: 600;
  margin-bottom: 0.5em;
}

h2 {
  color: #212121;
  font-size: 1.8em;
  font-style: italic;
  font-weight: 400;
}

h3 {
  color: #212121;
  font-size: 1.2em;
  font-weight: 600;
}

h4 {
  color: #707070;
  font-size: 0.75em;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 1em;
}

.intro {
  color: #000000;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1.7em;
  margin-bottom: 1.75em;
}

p,
ol {
  color: #464646;
  font-size: 1.2em;
  font-weight: 400;
  margin-bottom: 1.2em;
}

.dropzone {
}

.dropzone img {
  border: 2px gray solid;
  padding: 3px;
}

.dropzone--isActive img {
  border: 2px green dashed;
}

.ui.dimmer .ui.workaround.loader:before {
  border-color: rgba(0, 0, 0, 0.1);
}

.ui.dimmer .ui.workaround.loader:after {
  border-color: #767676 transparent transparent;
}

@media (max-width: 820px) {
  h1 {
    font-size: 2.6em;
  }

  h2 {
    font-size: 1.6em;
  }

  .intro {
    font-size: 1.1em;
    line-height: 1.6em;
  }
  p {
    font-size: 1em;
  }
}
