.SideDrawer {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    right: 0;
    top: 85px;
    z-index: 200;
    background-color: white;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    box-shadow: 0px 6px 20px rgba(0,0,0,0.4);
}

@media (min-width: 630px) {
    .SideDrawer {
        display: none;
    }
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(100%);
}

.Logo {
    height: 11%;
    margin-bottom: 32px;
}