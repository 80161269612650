.DrawerToggle {
    width: 32px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.DrawerToggle img {
    width: 100%;
}

@media (min-width: 630px) {
    .DrawerToggle {
        display: none;
    }
}