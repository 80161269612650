.Features {
   display: flex;
   flex-flow: row;
   justify-content: space-between;
   align-content: stretch;
   padding-bottom: 30px;
   
}

@media (max-width: 600px) {
   .Features {
      flex-flow: column;
   }
}
