.Content {
    margin: 0 auto;
    width: 1080px;
    box-sizing: border-box;
}

@media (max-width: 1200px) {
    .Content {
        width: 85vw;
    }
}