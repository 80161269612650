.Toolbar {
    height: 72px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
    box-shadow: 0px 5px 20px rgba(0,0,0,0.4);
}

.Toolbar nav {
    height: 100%;
}

.Logo {
    height: 90%;
}

@media (max-width: 629px)
{
    .DesktopOnly {
        display: none;
    }
}
