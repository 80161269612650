.MainArea {
   display: flex;
   flex-flow: row;
   margin-bottom: 50px;
}

.MainImage {
   align-self: center;
   flex-grow: 1;
   flex-basis: 0;
   text-align: center;
}

.MainContent {
   padding-top: 30px;
   margin-top: 50px;
   flex-grow: 1;
   flex-basis: 0;
}

.Anchor {
   position: relative;
}

.MainArea ul {
   display: flex;
   flex-flow: row;
   justify-content: space-around;
   padding-top: 30px;
}

.MainContent img {
   height: 60px;
}

 .Separator {
   background-image: url(../../assets/images/bg.svg);
   background-repeat: no-repeat;
   background-position-x: center;
   background-position-y: top;
   background-size: 3840px 1024px;
   height: 1024px;
   position: absolute;
   width: 100vw;
   left: -50vw;
   top: -450px;
   z-index: -10;
} 

@media (max-width: 820px) {
   .MainArea {
      flex-flow: column-reverse;
   }

   .MainContent {
      padding:  0;
      margin: 0;
      flex-grow: 1;
      flex-basis: 0;
   }

   .MainContent img {
      height: 42px;
   }

   .Separator {
      left: -7.5vw; 
      top: -200px  
   }

   .MainArea ul {
      margin: 0 auto;
      width: 70%;
      padding-top: 30px;
   }
}
