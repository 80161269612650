.grid {
  display: grid;

  background-color: lightsalmon;
  width: 100%;
  /* height: 1200px; */
  grid-auto-flow: column;

  border-top: 1px solid #000;
  border-left: 1px solid #000;
}

.clue {
  background-color: #000;
  color: white;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
  font-size: 0.7rem;
}

.clueCell {
  padding: 3px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clueCell:nth-child(n + 2) {
 
  border-top: 1px solid gray;
}

.cell {
  background-color: #fff;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  font-size: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
